import { useEffect, useState } from "react";

const useIsMobile = (size) => {
  const [isMobile, setIsMobile] = useState(false);

  // listen if the user resize the screen and call handleresize fn
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // check at first render the size of the screen to display the right menu config
  useEffect(() => {
    if (window.innerWidth < size) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [size]);

  // handle the state of the menu when user resize the screen
  const handleResize = () => {
    if (window.innerWidth < 992) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  return isMobile;
};

export default useIsMobile;
