const Wrapper = (props) => {
  const style = {
    display: "flex",
    justifyContent: props.justify,
    alignItems: "center",
    maxWidth: "1200px",
    margin: "0 auto",
    height: "100%",
  };
  return (
    <div className={props.className} style={style}>
      {props.children}
    </div>
  );
};

export default Wrapper;
