import { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import style from "./BannerCookies.module.scss";
import Wrapper from "../../layout/Wrapper";
import Link from "next/link";
import { useRouter } from "next/router";

const BannerCookies = () => {
  const [showBanner, setShowBanner] = useState(false);
  const { t } = useTranslation();
  const { locale } = useRouter();

  const handleCloseCookies = () => {
    window.localStorage.setItem("cookiesChecked", true);
    setShowBanner(false);
  };

  useEffect(() => {
    const res = window.localStorage.getItem("cookiesChecked");
    console.log(res);
    if (res === "true") setShowBanner(false);
    else setShowBanner(true);
  }, []);

  return (
    <div
      className={
        showBanner
          ? style["banner-cookies"]
          : style["banner-cookies"] + " " + style["banner-cookies--close"]
      }>
      <Wrapper justify={"center"}>
        <p className={style["banner-cookies__text"]}>
          {t("cookies.text")}
          <Link href={`/${locale}${t("cookies.link")}`}>
            <a className={style["banner-cookies__link"]}>{t("cookies.learnMore")}</a>
          </Link>
        </p>
        <div className={style["banner-cookies__btn"]} onClick={handleCloseCookies}>
          OK
        </div>
      </Wrapper>
    </div>
  );
};

export default BannerCookies;
