import config from "../config/index";

export const sendMessage = async (args, token) => {
  try {
    if (!token) return Promise.reject(new Error(`No token found for action save_contact`));

    const fetchObject = {
      method: "POST",
      body: JSON.stringify(args),
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await fetch(`${config.message}/message`, fetchObject);

    if (res.ok) return await res.json();
    throw new Error(`Call on ${res.url} return status code ${res.status}`);
  } catch (error) {
    console.error(error);
  }
};
