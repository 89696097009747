import { CgCloseO } from "react-icons/cg";
import style from "./ContactModal.module.scss";
import ContactForm from "../ContactForm/ContactForm";

const ContactModal = ({ setDisplayForm }) => {
  return (
    <div className={style["contact-modal"]}>
      <div className={style["contact-modal__formu"]}>
        <div
          className={style["contact-modal__icon"]}
          onClick={() => setDisplayForm(false)}>
          <CgCloseO size={22} />
        </div>
        <ContactForm />
      </div>
    </div>
  );
};

export default ContactModal;
