import style from "./SquareDesign.module.scss";

const SquareDesign = () => {
  return (
    <div className={style["square-design__a"]}>
      <div className={style["square-design__b"]}>
        <div className={style["square-design__c"]}>
          <div className={style["square-design__d"]}>
            <div className={style["square-design__e"]}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SquareDesign;
