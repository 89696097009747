import style from "./Search.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import useIsMobile from "../../hooks/useIsMobile";
import { MdSearch } from "react-icons/md";
import { useTranslation } from "next-i18next";
import useClickOutside from "../../hooks/useClickOutside";
import { useRouter } from "next/router";

const Search = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [input, setInput] = useState("");
  const { t } = useTranslation();

  const containerRef = useRef();

  const router = useRouter();

  const isMobile = useIsMobile(992);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input === "") return;
    router.push(`/${router.locale}?search=${input}`);
  };

  useEffect(() => isMobile && setShowSearch(true), [isMobile]);

  const handleShowSearch = useCallback(() => {
    if (!isMobile) setShowSearch(!showSearch);
  }, [isMobile, showSearch]);

  useClickOutside(containerRef, () => setShowSearch(false));

  return (
    <div className={style.Search} ref={containerRef}>
      <form onSubmit={handleSubmit} className={style.Form}>
        <label htmlFor="Search">Search</label>
        <input
          id="search"
          onChange={(e) => setInput(e.target.value)}
          value={input}
          onMouseOver={() => setShowSearch(true)}
          className={showSearch ? style.InputOpen + " " + style.Input : style.Input}
          placeholder={showSearch || isMobile ? t("header.search") : null}
        />
        <MdSearch
          color={showSearch ? "#6f54e9" : "white"}
          className={showSearch ? style.Icon + " " + style.IconOpen : style.Icon}
          onClick={() => handleShowSearch()}
        />
      </form>
    </div>
  );
};

export default Search;
