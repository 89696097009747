import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import Image from "next/image";
import Link from "next/link";
import style from "./Footer.module.scss";
import Button from "../Button/Button";
import GamepadLogo from "../../img/logo-fd-code.svg";
import Social from "../Social/Social";

const Footer = ({ handleClickContact }) => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  return (
    <footer className={style["footer"]}>
      <div className={style["footer__wrapper"]}>
        <Social
          logoSize={45}
          text={t("social.footer")}
          color="black"
          column={true}
          className={style["footer__social"]}
        />
        <div className={style["footer__btns"]}>
          <Link href={`/${locale}/about`}>{t("header.menu.about")}</Link>
          <button onClick={handleClickContact} className={style["footer__btns-add"]}>
            {t("footer.add")}
          </button>
          <Button
            className={style["footer__btns-contact"]}
            text={t("footer.contact")}
            onClick={handleClickContact}
            padding="1rem 1.5rem"
            fontSize="16px"
          />
        </div>
        <div className={style["footer__logo"]}>
          <Image
            src={GamepadLogo.src}
            alt="Gamepad Logo"
            width={GamepadLogo.width}
            height={GamepadLogo.height}
          />
        </div>
      </div>
      <div className={style["footer__copyright-container"]}>
        <p className={style["footer__copyright"]}>{t("footer.copyright")}</p>
        <Link href={`/${locale}${t("cookies.link")}`}>
          <a className={style["footer__copyright-btn"]}>{t("footer.privacy")}</a>
        </Link>
      </div>
    </footer>
  );
};
export default Footer;
