import { useTranslation } from "next-i18next";
import Image from "next/image";
import styles from "./Social.module.scss";
import twitterLogo from "../../img/twitter_dark.png";
import twitterLogoWhite from "../../img/twitter_white.png";
import facebookLogo from "../../img/facebook_dark.png";
import facebookLogoWhite from "../../img/facebook_white.png";

const Social = ({ flex, logoSize, color, text, column, className }) => {
  const { t } = useTranslation();

  return (
    <div
      className={styles["Social"] + " " + className}
      style={{ flexDirection: column ? "column" : "row", alignItems: column ? "start" : "" }}>
      <p style={{ color: color }} className={styles["Social__title"]}>
        {text}
      </p>
      <div className={styles["Social__icons"]} style={{ marginTop: column ? "10px" : "", marginLeft: flex ? "10px" : "" }}>
        <a href="https://x.com/FriendCodeApp" target="_blank" rel="noopener noreferrer">
          <Image src={color === "white" ? twitterLogoWhite.src : twitterLogo.src} alt="twitter logo" width={logoSize} height={logoSize}/>
        </a>
      </div>
    </div>
  );
};

export default Social;
