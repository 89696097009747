import style from "./ContactForm.module.scss";
import { useState } from "react";
import SquareDesign from "../SquareDesign/SquareDesign";
import { useTranslation } from "next-i18next";
import { sendMessage } from "../../actions/message";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Button from "../Button/Button";
import config from "../../config";

const ContactForm = () => {
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    sent: false,
  });
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value, sent: false });
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.name === "") {
      setError("name");
      return;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      setError("email");
      return;
    }

    if (formData.message.length < 50) {
      setError("message");
      return;
    }
    const token = await executeRecaptcha(`save_contact`);

    const contact = {
      name: formData.name,
      email: formData.email,
      content: formData.message,
      notification: config.messageEmail,
      origin: window.location.href,
      token: token,
    };

    sendMessage(contact, token);

    setFormData({ name: "", email: "", message: "", sent: true });
  };

  return (
    <section className={style["contact"]} id="contact-form">
      <h3 className={style["contact__title"]}>{t("contact.title")}</h3>
      <h5 className={style["contact__subtitle"]}>{t("contact.description")}</h5>
      <p className={style["contact__text"]}>{t("contact.subtitle")} </p>
      <form action="submit" className={style["contact__form"]} onSubmit={handleSubmit}>
        <input
          value={formData.name}
          aria-label={t("contact.name")}
          placeholder={t("contact.name")}
          type="text"
          name="name"
          onChange={handleChange}
          className={style["contact__input"]}
        />
        {error === "name" && <p className={style["contact__error"]}>{t("contact.errorName")}</p>}
        <input
          value={formData.email}
          aria-label={t("contact.email")}
          placeholder={t("contact.email")}
          type="email"
          name="email"
          onChange={handleChange}
          className={style["contact__input"]}
        />
        {error === "email" && <p className={style["contact__error"]}>{t("contact.errorEmail")}</p>}
        <textarea
          value={formData.message}
          aria-label={t("contact.message")}
          placeholder={t("contact.message")}
          name="message"
          cols="30"
          rows="10"
          onChange={handleChange}
          className={style["contact__text-area"]}></textarea>
        {formData.message.length > 0 && (
          <p
            className={style["contact__limit"]}
            style={{
              color: error === "message" ? "red" : null,
            }}>{`${formData.message.length} / 50`}</p>
        )}
        <div className={style["contact__btn"]} style={{ opacity: formData.sent ? "0.8" : null }}>
          <Button
            text={t("contact.btn")}
            onSubmit={handleSubmit}
            padding="10px 20px"
            fontSize="16px"
          />
        </div>
      </form>
      <div className={style["design__1"]}>
        <SquareDesign />
      </div>
      <div className={style["design__2"]}>
        <SquareDesign />
      </div>
      {formData.sent && <p className={style["contact__thank"]}>{t("contact.thanks")}</p>}
    </section>
  );
};

export default ContactForm;
