import style from "./Button.module.scss";

const Button = ({ text, padding, fontSize, onSubmit, onClick, className }) => {
  return (
    <button
      onClick={onClick}
      onSubmit={onSubmit}
      className={style.Button + " " + className}
      style={{ padding: padding, fontSize: fontSize }}>
      {text}
    </button>
  );
};

export default Button;
